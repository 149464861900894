// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ui/alert-dialog.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ui/alert-dialog.tsx");
  import.meta.hot.lastModified = "1727806535421.0706";
}
// REMIX HMR END

"use client";
import * as React from "react";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import { cn } from "~/utils";
import { buttonVariants } from "~/components/ui/button";
const AlertDialog = AlertDialogPrimitive.Root;
const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
const AlertDialogPortal = ({
  className,
  children,
  ...props
}) => <AlertDialogPrimitive.Portal className={cn(className)} {...props}>
    <div className="fixed inset-0 z-50 flex items-end justify-center sm:items-center">
      {children}
    </div>
  </AlertDialogPrimitive.Portal>;
_c = AlertDialogPortal;
AlertDialogPortal.displayName = AlertDialogPrimitive.Portal.displayName;
const AlertDialogOverlay = React.forwardRef(_c2 = ({
  className,
  children,
  ...props
}, ref) => <AlertDialogPrimitive.Overlay className={cn("fixed inset-0 z-50 bg-background/80 backdrop-blur-sm transition-opacity animate-in fade-in", className)} {...props} ref={ref} />);
_c3 = AlertDialogOverlay;
AlertDialogOverlay.displayName = AlertDialogPrimitive.Overlay.displayName;
const AlertDialogContent = React.forwardRef(_c4 = ({
  className,
  ...props
}, ref) => <AlertDialogPortal>
    <AlertDialogOverlay />
    <AlertDialogPrimitive.Content ref={ref} className={cn("fixed z-50 grid w-full max-w-lg scale-100 gap-4 border bg-background p-6 opacity-100 shadow-lg animate-in fade-in-90 slide-in-from-bottom-10 sm:rounded-lg sm:zoom-in-90 sm:slide-in-from-bottom-0 md:w-full", className)} {...props} />
  </AlertDialogPortal>);
_c5 = AlertDialogContent;
AlertDialogContent.displayName = AlertDialogPrimitive.Content.displayName;
const AlertDialogHeader = ({
  className,
  ...props
}) => <div className={cn("flex flex-col space-y-2 text-center sm:text-left", className)} {...props} />;
_c6 = AlertDialogHeader;
AlertDialogHeader.displayName = "AlertDialogHeader";
const AlertDialogFooter = ({
  className,
  ...props
}) => <div className={cn("flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2", className)} {...props} />;
_c7 = AlertDialogFooter;
AlertDialogFooter.displayName = "AlertDialogFooter";
const AlertDialogTitle = React.forwardRef(_c8 = ({
  className,
  ...props
}, ref) => <AlertDialogPrimitive.Title ref={ref} className={cn("text-lg font-semibold", className)} {...props} />);
_c9 = AlertDialogTitle;
AlertDialogTitle.displayName = AlertDialogPrimitive.Title.displayName;
const AlertDialogDescription = React.forwardRef(_c10 = ({
  className,
  ...props
}, ref) => <AlertDialogPrimitive.Description ref={ref} className={cn("text-sm text-muted-foreground", className)} {...props} />);
_c11 = AlertDialogDescription;
AlertDialogDescription.displayName = AlertDialogPrimitive.Description.displayName;
const AlertDialogAction = React.forwardRef(_c12 = ({
  className,
  ...props
}, ref) => <AlertDialogPrimitive.Action ref={ref} className={cn(buttonVariants(), className)} {...props} />);
_c13 = AlertDialogAction;
AlertDialogAction.displayName = AlertDialogPrimitive.Action.displayName;
const AlertDialogCancel = React.forwardRef(_c14 = ({
  className,
  ...props
}, ref) => <AlertDialogPrimitive.Cancel ref={ref} className={cn(buttonVariants({
  variant: "outline"
}), "mt-2 sm:mt-0", className)} {...props} />);
_c15 = AlertDialogCancel;
AlertDialogCancel.displayName = AlertDialogPrimitive.Cancel.displayName;
export { AlertDialog, AlertDialogTrigger, AlertDialogContent, AlertDialogHeader, AlertDialogFooter, AlertDialogTitle, AlertDialogDescription, AlertDialogAction, AlertDialogCancel };
var _c, _c2, _c3, _c4, _c5, _c6, _c7, _c8, _c9, _c10, _c11, _c12, _c13, _c14, _c15;
$RefreshReg$(_c, "AlertDialogPortal");
$RefreshReg$(_c2, "AlertDialogOverlay$React.forwardRef");
$RefreshReg$(_c3, "AlertDialogOverlay");
$RefreshReg$(_c4, "AlertDialogContent$React.forwardRef");
$RefreshReg$(_c5, "AlertDialogContent");
$RefreshReg$(_c6, "AlertDialogHeader");
$RefreshReg$(_c7, "AlertDialogFooter");
$RefreshReg$(_c8, "AlertDialogTitle$React.forwardRef");
$RefreshReg$(_c9, "AlertDialogTitle");
$RefreshReg$(_c10, "AlertDialogDescription$React.forwardRef");
$RefreshReg$(_c11, "AlertDialogDescription");
$RefreshReg$(_c12, "AlertDialogAction$React.forwardRef");
$RefreshReg$(_c13, "AlertDialogAction");
$RefreshReg$(_c14, "AlertDialogCancel$React.forwardRef");
$RefreshReg$(_c15, "AlertDialogCancel");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;