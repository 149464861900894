// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/services/navigation.service.ts"
);
import.meta.hot.lastModified = "1727806535449.071";
}
// REMIX HMR END

import { useLocation } from "@remix-run/react"
import { useOptionalUser, userHasPermission, userHasRole } from "~/utils"

export function getNavigation() {
  const user = useOptionalUser()
  const location = useLocation()
  if (!user) {
    return []
  }

  const links = [
    {
      label: 'Facilities',
      to: '/facilities',
    },
    {
      label: 'Patients',
      to: '/patients',
    },
    // {
    //   label: 'Leads',
    //   to: '/leads',
    // },
    // {
    //   label: 'Forms',
    //   to: '/forms',
    // },
  ]

  if (userHasPermission('inbox.list.any')) {
  	links.push({
      label: 'Alerts',
      to: '/alerts',
		})
  }

  if (userHasRole(user, 'administrator')) {
  	// links.push({
		// 	label: 'Reports',
		// 	to: '/reports',
		// })
  }

  return links
}